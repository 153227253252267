var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mini"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.secret),expression:"secret"}],staticClass:"secret",attrs:{"type":"password","autocomplete":"off","placeholder":"© 2021 jinyaoMa"},domProps:{"value":(_vm.secret)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleLoad.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.secret=$event.target.value}}}),_c('div',{staticClass:"tab"},[_c('div',{staticClass:"tab-item",on:{"click":_vm.handleTab}},[_c('i',{staticClass:"fas fa-images"})]),_vm._m(0)]),_c('div',{staticClass:"list"},[(_vm.parentPath != null)?_c('div',{staticClass:"list-item"},[_c('router-link',{staticClass:"list-item-link",attrs:{"to":{
          query: {
            currentPath: _vm.parentPath,
          },
        }}},[_c('i',{staticClass:"fas fa-reply fa-fw"}),_vm._v(" "+_vm._s(_vm.$locale.home.back2Parent)+" ")])],1):_vm._e(),_vm._l((_vm.currentFiles),function(file,i){return _c('div',{key:file.ID,staticClass:"list-item"},[(file.Type === 'directory')?_c('router-link',{staticClass:"list-item-link",attrs:{"to":{
          query: {
            currentPath: file.Path,
          },
        }}},[_c('i',{staticClass:"fas fa-folder fa-fw"}),_vm._v(" "+_vm._s(file.Name)+" ")]):_c('div',{staticClass:"list-item-text",on:{"click":function($event){return _vm.handlePlay(file, i)}}},[_c('i',{staticClass:"fas fa-file fa-fw"}),_vm._v(" "+_vm._s(file.Name)+" ")])],1)})],2),(_vm.canPlay)?_c('div',{staticClass:"video-section"},[_c('i',{staticClass:"fas fa-times video-close",on:{"click":_vm.handleClose}}),_c('video-player',{ref:"videoPlayer",attrs:{"options":_vm.playerOptions},on:{"ended":function($event){return _vm.onPlayerEnded($event)}}})],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-item active"},[_c('i',{staticClass:"fas fa-video"})])}]

export { render, staticRenderFns }